<template>
    <div>
        <b-modal
    v-model="withdrawModal"
     style="padding-top: 10vh;"
     scrollable
     no-close-on-backdrop
     hide-header-close
     size="md"
   >
     <template #modal-footer="{}">
      <img v-if="wthdrawLoader" height="40" width="40" src="/new-loader.svg" />
       <b-button size="sm" variant="outline-primary" @click="cancelWithdraw()">
         {{ $t("back") }}
       </b-button>
       <b-button :disabled="wthdrawLoader" id="disabled_button" size="sm" variant="primary" @click="submitWithdraw()">
         {{ $t("submit") }}
       </b-button>
     </template>
     <validation-observer ref="withdrawValidation">
      <b-form @submit.prevent>
    <div>
     <span>
      <h3>{{ $t("saldo_balance") }}</h3>
     </span>
     <span>
      <span v-if="withdrawData.balance.openBalance">
        {{ withdraw_currency }} {{withdrawData.balance.openBalance}}</span
      >
      <span v-else>{{ withdraw_currency }} 00’000.00</span>
     </span>
     <span>
      <p class="font-md">

      </p>
    </span>
        <span>
            <!--<p class="font-md" v-if="selectedCurrency">{{ $t('currency_choice') }} <b>{{ selectedCurrency }}</b></p>-->
           <b-dropdown id="dropdown-custom-options" :text="$t('select_currency')" class="mb-2" variant="outline-dark">
              <b-dropdown-item
                  v-for="option in currencies"
                  :key="option.id"
                  @click="changeCurrency(option.name, option.id)"
              >
                <!--<b-img-->
                  <!--:src="option.flag"-->
                  <!--height="14px"-->
                  <!--width="22px"-->
                  <!--:alt="option.value"-->
                  <!--/>-->
                {{ option.name }}
              </b-dropdown-item>
                <!--<template v-if="this.$store.state.auth.user.crole == 'buyer'">-->
                    <!--<b-dropdown-item>BTC</b-dropdown-item>-->
                    <!--<b-dropdown-item>ETH</b-dropdown-item>-->
                    <!--<b-dropdown-item>USDT</b-dropdown-item>-->
                <!--</template>-->
            </b-dropdown>
        </span>
    <span>
      <b-form-group
        id="withdraw_amount"
        class="font-weight-bolder"
        :label="$t('specify_withdraw')"
        label-for="withdraw_amount"
      >
        <validation-provider
          #default="{ errors }"
          :name="$t('withdraw_amount')"
          rules="required"
        >
         <b-form-input
           id="amount-input"
           v-model.trim="withdrawData.withdraw_amount"
           type="number"
           :min="parseInt(withdrawData.withdraw_amount)"
           step="0.01"
           required
           autocomplete="off"
         ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
     </b-form-group>
    </span>
    <div>
        <b-col md="12" class="pt-0" >
            <b-form-radio
                :disabled="disableInput"
                class="mb-1"
                value="own"
                name="check-button"
                @change="ChangeAccount('own')"
            >
                {{ $t("own") }}
            </b-form-radio>

            <b-form-radio
                :disabled="disableInput"
                value="external"
                name="check-button"
                @change="ChangeAccount('external')"
            >
                {{ $t("external") }}
            </b-form-radio>
        </b-col>
    </div>
    <hr>
   <div class="mb-1 font-weight-bold"> {{ $t("enter_bank_info") }}</div>
        <div v-if="choseAccount != null">
             <span class="d-flex justify-content-between ">
      <span>{{ $t("account_owner") }}</span>
      <span>
          <b-form-group
              id="account_owner"
              class="font-weight-bolder"
          >
        <validation-provider
            #default="{ errors }"
            :name="$t('account_owner')"
            rules="required"
        >
         <b-form-input
             id="account_owner"
             v-model.trim="withdrawData.account_owner"
             required
         ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
     </b-form-group>
    </span>
    </span>
            <span class="d-flex justify-content-between ">
      <span>{{ $t("iban") }}</span>
      <span>
          <b-form-group id="iban"  class="font-weight-bolder">
        <validation-provider
            #default="{ errors }"
            :name="$t('iban')"
            rules="required"
        >
         <b-form-input
             id="iban"
             v-model.trim="withdrawData.iban"
             required
         ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
     </b-form-group>
    </span>
    </span>
            <span class="d-flex justify-content-between ">
      <span>{{ $t("bank_name") }}</span>
      <span>
          <b-form-group
              id="bank_name"
              class="font-weight-bolder"
          >
        <validation-provider
            #default="{ errors }"
            :name="$t('bank_name')"
            rules="required"
        >
         <b-form-input
             id="amount-input"
             v-model.trim="withdrawData.bank_name"
             required
         ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
     </b-form-group>
    </span>
    </span>
            <span class="d-flex justify-content-between ">
      <span>{{ $t("bic") }}</span>
      <span>
          <b-form-group
              id="bic"
              class="font-weight-bolder"
          >
        <validation-provider
            #default="{ errors }"
            :name="$t('bic')"
            rules="required"
        >
         <b-form-input
             id="amount-input"
             v-model.trim="withdrawData.bic"
             required
         ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
     </b-form-group>
    </span>
    </span>
            <span class="d-flex justify-content-between ">
      <span>{{ $t("reference_number") }}</span>
      <span>
          <b-form-group
              id="reference_number"
              class="font-weight-bolder"
          >
        <validation-provider
            #default="{ errors }"
            :name="$t('reference_number')"
            rules="required"
        >
         <b-form-input
             id="reference_number"
             v-model.trim="withdrawData.reference_number"
             :disabled="referenceNumberDisable"
             required
         ></b-form-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
     </b-form-group>
    </span>

    </span>
            <span v-if="choseAccount == 'external'">
                <span style="font-size: 12px;">{{ $t('external_account_withdraw_accept_text') }}</span>

                 <b-form-group class="mt-1">
                    <validation-provider
                        #default="{ errors }"
                        name="confirmed"
                        rules="required"
                    >
                        <b-form-checkbox v-model="isConfirmed">
                        </b-form-checkbox>
                      <small class="text-danger">{{ errors[0] }}</small>

                    </validation-provider>
                 </b-form-group>
            </span>
        </div>
    </div>
  </b-form>
   </validation-observer>
   </b-modal>
    </div>
</template>
<script>
import i18n from "@/libs/i18n";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { BImg } from "bootstrap-vue";

export default{
    props: {
    withdrawModal: Boolean,
    wthdrawLoader: Boolean,
    withdrawData: Object,
    oldWithdrawData: Object,
  },
  components: {
    ValidationProvider,
    ValidationObserver,
    i18n,
    BImg
  },
  data() {
    return {
        disableInput: false,
        referenceNumberDisable: true,
        choseAccount: null,
        AmountSelectFields: null,
        // selectedCurrency: null,
        isConfirmed: null,
        // SellerAmountSelectFields: [
        //     { value: 'CHF', text: 'CHF', flag: require('@/assets/images/currency/chf.png') },
        //     { value: 'EUR', text: 'EUR', flag: require('@/assets/images/currency/eur.png') },
        //     { value: 'USD', text: 'USD', flag: require('@/assets/images/currency/usd.png') },
        //     { value: 'GBP', text: 'GBP', flag: require('@/assets/images/currency/gbp.png') },
        // ],
        //
        // BuyerAmountSelectFields: [
        //     { value: 'CHF', text: 'CHF', flag: require('@/assets/images/currency/chf.png') },
        //     { value: 'EUR', text: 'EUR', flag: require('@/assets/images/currency/eur.png') },
        //     { value: 'USD', text: 'USD', flag: require('@/assets/images/currency/usd.png') },
        //     { value: 'GBP', text: 'GBP', flag: require('@/assets/images/currency/gbp.png') },
        //     { value: 'BTC', text: 'BTC', flag: require('@/assets/images/currency/btc.png') },
        //     { value: 'ETH', text: 'ETH', flag: require('@/assets/images/currency/eth.png') },
        //     { value: 'USDT', text: 'USDT', flag: require('@/assets/images/currency/usdt.png') },
        // ],

        dataToSubmit: this.withdrawData,
        loader: this.wthdrawLoader,
        currencies: null,
        withdraw_currency: "CHF",
        withdraw_currency_id: 1,
    }
  },
  created(){
    this.getCurrencies();
    this.changeAmountFields()
  },
  methods:{

    getCurrencies() {
      this.$http.post("/currency").then((response) => {
        if (response.data.status == 200) {
          this.currencies = response.data.value;;
        }
      });
    },

    changeCurrency(value, id) {

      if(value) {
        this.withdraw_currency = value;
      }

      if(id) {
        this.withdraw_currency_id = id
      }

      this.$emit('getBalance',id);

    },

    ChangeAccount(type) {
      this.choseAccount = type;
      if(type == 'external') {
        this.disableInput = true;
        this.referenceNumberDisable = false;
        this.withdrawData.account_owner = null;
        this.withdrawData.iban = null;
        this.withdrawData.bank_name = null;
        this.withdrawData.bic = null;
        this.withdrawData.reference_number = null;
        console.log("oldWithdrawData", this.oldWithdrawData);
      }
      // else {
      //   console.log("esssss", this.dataToSubmit);
      //   this.withdrawData.withdraw_amount = this.oldWithdrawData.withdraw_amount;
      //   this.withdrawData.account_owner = this.oldWithdrawData.account_owner;
      //   this.withdrawData.iban = this.oldWithdrawData.iban;
      //   this.withdrawData.bank_name = this.oldWithdrawData.bank_name;
      //   this.withdrawData.bic = this.oldWithdrawData.bic;
      //   this.withdrawData.reference_number = this.oldWithdrawData.reference_number;
      // }

    },

    changeAmountFields() {
      this.AmountSelectFields = this.$store.state.auth.user.crole == 'seller' ? this.SellerAmountSelectFields : this.BuyerAmountSelectFields
    },
    cancelWithdraw(){
      // console.log(77777, this.selectedCurrency);
        this.$emit('cancelWithdraw')
    },
    submitWithdraw(){
    this.dataToSubmit = this.withdrawData;

      this.$refs.withdrawValidation.validate().then((valid) => {
        if (valid) {
            document.getElementById('disabled_button').disabled = true

            this.loader = true;
            let data = {
                withdraw_amount : this.dataToSubmit.withdraw_amount,
                account_owner : this.dataToSubmit.account_owner,
                iban: this.dataToSubmit.iban,
                bank_name : this.dataToSubmit.bank_name,
                bic: this.dataToSubmit.bic,
                reference_number: this.dataToSubmit.reference_number,
                currency_id: this.withdraw_currency_id,
                account_type: this.choseAccount
            };

    this.$http
        .post(`/company/withdraw`, data)
        .catch((error)=>{
             if(error){
              this.loader = false;
             }
        })
        .then((res) => {
          if(res){
            if (res.data.status == 200) {
               this.showSuccessMessage(i18n.t("success"));
                 this.loader = false;
                 this.$emit('getBalance',this.withdraw_currency_id);
            }else{
              this.$swal({
                position: "center",
                icon: "error",
                title: res.data.message,
                showConfirmButton: false,
                timer: 1500,
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                buttonsStyling: false,
              });
            }
            this.loader = false;
            this.dataToSubmit.withdraw_amount = null;
            this.$emit('cancelWithdraw')


          }
        });
      }
      });
  },
  showSuccessMessage(message, type) {
      let text = this.$t(message);
      this.$swal({
        position: "center",
        icon: "success",
        title: text,
        showConfirmButton: false,
        timer: 1500,
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });

    },
  }
}

</script>